import View from './View';

class linksView extends View {
    _parentElement = document.querySelector('.links');

    addWorkHandler(handler) {
        this._parentElement.addEventListener('click', function (e) {
            const btn = e.target.closest('.work-link')
            if (!btn) return
            handler();
        })
    }

    addContactHandler(handler) {
        this._parentElement.addEventListener('click', function (e) {
            const btn = e.target.closest('.contact-link')
            if (!btn) return
            handler();
        })
    }

    addAboutHandler(handler) {
        this._parentElement.addEventListener('click', function (e) {
            const btn = e.target.closest('.about-link')
            if (!btn) return
            handler();
        })
    }

    addReturnHandler(handler) {
        this._returnButton.addEventListener('click', function (e) {
            const btn = e.target.closest('.return-btn')
            if (!btn) return
            handler();
        })
        this._floatingReturnButton.addEventListener('click', function (e) {
            const btn = e.target.closest('.floating-back-btn')
            if (!btn) return
            handler();
        })
    }

    renderGenericHTMLContent(data) {
        this._tagline.insertAdjacentHTML('afterbegin', data)
    }
}

export default new linksView();
