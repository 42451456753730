import View from './View';

class passwordView extends View {
    _badPasswordContent = `<span>Incorrect password</span>`;
    _badRequestContent = `<span>Unable to reach server</span>`

    addSubmissionHandler(handler) {
        this._passwordForm.addEventListener('submit', function (e) {
            e.preventDefault();

            const input = document.getElementById('password');

            handler(input.value);

            input.value = '';
        })
    }

    renderBadPassword() {
        this._passwordError.innerHTML = '';
        this._passwordError.insertAdjacentHTML('afterbegin', this._badPasswordContent);
    }

    renderBadRequest() {
        this._passwordError.innerHTML = '';
        this._passwordError.insertAdjacentHTML('afterbegin', this._badRequestContent);
    }

    removeWrapper() {
        this._passwordWrapper.classList.toggle('password-active')
    }

}

export default new passwordView();
