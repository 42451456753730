import * as model from "./model.js";
import aboutView from "./view/aboutView.js";
import contactView from "./view/contactView.js";
import workView from "./view/workView.js";
import linksView from "./view/linksView.js";
import passwordView from "./view/passwordView.js";

const controlAboutClick = function () {
    aboutView.loadAboutContent();
}

const controlContactHandler = function () {
    contactView.loadContactContent();
}

const controlWorkHandler = function () {
    workView.loadWorkContent();
}

const controlReturn = function () {
    linksView._transitionContent();
    workView.expandSection();
}

const controlWorkBtn = function (btnPressed) {
    workView.expandSection(btnPressed.dataset.section);
}

const controlPasswordSubmission = async function (value) {
    const res = await model.passwordAttempt(value);

    if (res == null) {
        passwordView.renderBadPassword();
        return
    };

    if (res == false) {
        passwordView.renderBadRequest();
        return
    }

    model.handleImportData(res);
    controlImportData();

}

const controlImportData = function () {
    contactView.parseContactContent(model.state.contactContent);
    aboutView.parseAboutContent(model.state.aboutContent);
    workView.parseWorkContent(model.state.workContent);
    linksView.renderGenericHTMLContent(model.state.genericContent);
    passwordView.removeWrapper();
}


const init = function () {
    linksView.addAboutHandler(controlAboutClick);
    linksView.addContactHandler(controlContactHandler);
    linksView.addWorkHandler(controlWorkHandler);
    linksView.addReturnHandler(controlReturn);
    workView.addWorkBtnHandler(controlWorkBtn);
    passwordView.addSubmissionHandler(controlPasswordSubmission);
    model.importLocalData();
    if (model.validateData()) {
        controlImportData();
    }
    console.log(`%c/////////////////////////////////////`, 'background: #222; color: #bada55');
    console.log(`%c/// Handcrafted by Brandon Tingey ///`, 'background: #222; color: #bada55');
    console.log(`%c/////////////////////////////////////`, 'background: #222; color: #bada55');
}

init();
