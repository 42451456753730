import View from './View';

import struct1 from "url:../../img/work/struct1.png";
import struct2 from "url:../../img/work/struct2.png";
import struct3 from "url:../../img/work/struct3.png";
import struct4 from "url:../../img/work/struct4.png";
import scientist1 from "url:../../img/work/scientist1.png";
import scientist2 from "url:../../img/work/scientist2.png";
import origins1 from "url:../../img/work/origins1.png";
import origins2 from "url:../../img/work/origins2.png";
import origins3 from "url:../../img/work/origins3.png";
import mutant1 from "url:../../img/work/mutant1.png";
import mutant2 from "url:../../img/work/mutant2.png";
import mutant3 from "url:../../img/work/mutant3.gif";
import wsf1 from "url:../../img/work/wsf1.png";
import wsf2 from "url:../../img/work/wsf2.png";
import wsf3 from "url:../../img/work/wsf3.png";
import tooder1 from "url:../../img/work/tooder1.png";
import tooder2 from "url:../../img/work/tooder2.png";
import tooder3 from "url:../../img/work/tooder3.png";


class workView extends View {
    _parentElement = this._contentContainer;
    _imgArr = [struct1, struct2, struct3, struct4, scientist1, scientist2, origins1, origins2, origins3, mutant1, mutant2, mutant3, wsf1, wsf2, wsf3, tooder1, tooder2, tooder3]
    _workContent = ``;


    loadWorkContent() {
        this._clear();
        this._titleContent.insertAdjacentText('afterbegin', 'Work');
        this._contentContainer.insertAdjacentHTML('afterbegin', this._workContent);
        twttr.widgets.load(this._contentContainer);
        this._transitionContent();
    }

    addWorkBtnHandler(handler) {
        this._parentElement.addEventListener('click', function (e) {
            const btn = e.target.closest('.content-title');
            if (!btn) return
            handler(btn);
        })
    }

    expandSection(sectionId) {
        const sectionList = document.querySelectorAll('.content-section');

        sectionList.forEach(function (currentNode) {
            if (!currentNode.classList.contains('content-hidden')) {
                currentNode.classList.toggle('content-hidden');
                currentNode.previousElementSibling.classList.toggle('title-selected');
                return
            }
            // currentNode.classList.add('content-hidden')
            if (currentNode.classList.contains(`${sectionId}`)) {
                currentNode.classList.toggle('content-hidden');
                currentNode.previousElementSibling.scrollIntoView();
                currentNode.previousElementSibling.classList.toggle('title-selected');
            }
        })

    }

    parseWorkContent(data) {
        let parsedData = ``;
        data.forEach((str, i) => parsedData += `${str}${this._imgArr?.[i] ? this._imgArr?.[i] : ''}`)
        this._workContent = parsedData;
    }
}

export default new workView();
