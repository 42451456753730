import View from './View';

class contactView extends View {
    _contactContent = ``;

    loadContactContent() {
        this._clear();

        this._titleContent.insertAdjacentText('afterbegin', 'Contact');
        this._contentContainer.insertAdjacentHTML('afterbegin', this._contactContent);
        this._transitionContent();
    }

    parseContactContent(data) {
        let parsedData = ``;
        // data.forEach((str, i) => parsedData += `${str}${this._imgArr?.[i] ? this._imgArr?.[i] : ''}`)
        parsedData = data;
        // Use arr methods to insert iamges
        this._contactContent = parsedData;
    }

}

export default new contactView();
