import View from './View';

import mePhoto from "url:../../img/about/me.png";

class aboutView extends View {
    _aboutContent = ``;
    _imgArr = [mePhoto]


    loadAboutContent() {
        this._clear();

        this._titleContent.insertAdjacentText('afterbegin', 'About');
        this._contentContainer.insertAdjacentHTML('afterbegin', this._aboutContent);
        this._transitionContent();
    }

    parseAboutContent(data) {
        let parsedData = ``;
        data.forEach((str, i) => parsedData += `${str}${this._imgArr?.[i] ? this._imgArr?.[i] : ''}`)
        this._aboutContent = parsedData;
    }

}

export default new aboutView();
