import { async } from "regenerator-runtime";
import { APIURL, VERSIONID } from './config';

export const state = {
    versionId: ``,
    genericContent: ``,
    workContent: ``,
    aboutContent: ``,
    contactContent: ``,
}

export const passwordAttempt = async function (pw) {
    try {
        const response = await fetch(`${APIURL}${pw}`);
        return parseResponse(response);
    } catch {
        return false
    }
};

const parseResponse = async function (response) {
    //check if response was ok or fail

    if (response.status == 400) {
        return null;
    }

    if (response.status == 200) {
        const resJson = await response.json();
        const data = resJson.data;
        return data;
    }
}

export const handleImportData = function (data) {
    state.versionId = data[0].versionId;
    state.genericContent = data[1].content;
    state.workContent = data[2].content;
    state.aboutContent = data[3].content;
    state.contactContent = data[4].content;
    localStorage.setItem('state', JSON.stringify(state));
}

export const validateData = function () {
    if (state.versionId == VERSIONID) {
        return true
    }
    if (state.versionId != VERSIONID) {
        return false
    }
}

export const importLocalData = function () {
    const storedData = JSON.parse(localStorage.getItem('state'));

    if (!storedData) return

    state.versionId = storedData.versionId;
    state.genericContent = storedData.genericContent;
    state.workContent = storedData.workContent;
    state.aboutContent = storedData.aboutContent;
    state.contactContent = storedData.contactContent;
}
