export default class View {
    _topBlue = document.querySelector('.top-blue');
    _bottomBlue = document.querySelector('.bottom-blue');
    _titleSpace = document.querySelector('.name-space');
    _contentContainer = document.querySelector('.content-container');
    _titleContainer = document.querySelector('.desc-title-container');
    _titleContent = document.querySelector('.desc-title-content');
    _returnButton = document.querySelector('.return-btn');
    _floatingReturnButton = document.querySelector('.floating-back-btn');
    _floatingBar = document.querySelector('.floating-bar');
    _passwordForm = document.querySelector('.password-main-form');
    _passwordError = document.querySelector('.password-error-msg');
    _passwordWrapper = document.querySelector('.password-wrapper');
    _tagline = document.querySelector('.tag-line');

    _transitionContent() {
        this._topBlue.classList.toggle('move-out');
        this._bottomBlue.classList.toggle('move-in-down');
        this._contentContainer.classList.toggle('send-b-in');
        this._titleContainer.classList.toggle('send-b-in');
        this._floatingReturnButton.classList.toggle('floating-active');
        this._floatingBar.classList.toggle('active-bar');
    }

    _clear() {
        this._contentContainer.innerHTML = '';
        this._titleContent.innerHTML = '';
    }

    _renderTitleContainerContent(markup) {
        this._titleContent.insertAdjacentHTML('afterbegin', markup);
    }

}
